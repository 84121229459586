









import {Vue, Component, Prop} from 'vue-property-decorator';
import SelectField from '@/shared/resources/components/forms/fields/SelectField.vue';
import Locale from '@/core/locale/Locale';
import { PaymentDeliveryInterface, PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';
import { SelectFieldOptionInterface } from '@/shared/lib/interfaces/SelectFieldOptionInterface';
import { DeliveryInterface } from '@/modules/deliveries/interfaces/DeliveriesInterface';

@Component({
  components: {
    SelectField,
  },
})
export default class DeliveriesByPaymentsTypesSelectField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: PaymentDeliveryInterface;
  @Prop() private paymentsDeliveries!: PaymentsTypeInterface[];

  /**
   * Handle delivery value change
   */
  private get delivery(): PaymentDeliveryInterface {
    return this.value;
  }

  private set delivery(value: PaymentDeliveryInterface) {
    this.$emit('input', value);
  }

  /**
   * Getters
   */
  private get options(): SelectFieldOptionInterface[] {
    return this.paymentsDeliveries.reduce((result: any[], option: PaymentsTypeInterface) => {
      result.push({
        text: this.$t('paymentsTypes.' + option.key + '.name'),
        isHeader: true,
      });

      option.deliveries!.forEach((delivery: DeliveryInterface) => {
        result.push({
          text: `${this.$t('deliveries.' + delivery.key + '.name')} (${Locale.priceWithCurrency(delivery.price!)})`,
          value: {
            paymentType: option.key,
            delivery: delivery.key,
          },
        });
      });

      return result;
    }, []);
  }
}
